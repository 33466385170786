import React from 'react';
import replace from 'lodash/replace';
import { useTranslation } from 'react-i18next';

import { Inputs } from '@features/cashout/components/stateInputs';
import { useCashoutItemModel } from '@features/cashout/useCashoutItemModel';
import { IPartCashoutButtons } from '@features/cashout/components/partCashoutButtons/PartCashoutButtons.types';

import { formatCashoutOffer } from '@common/helpers/cashoutHelper';
import { isDesktopView } from '@common/helpers/deviceUtil';
import numeral from '@common/helpers/numeralHelper';
import { STATE_QUEUED } from '@common/providers/cashout/state';

import { ButtonSize, ButtonType } from '@ui/components/buttons/Button.styled';
import { VirtualKeyboard } from '@ui/components/virtual';
import { StyledButton } from '@ui/components/buttons';

import * as S from './PartCashoutButtons.styled';

const PartCashoutButtons: React.FC<IPartCashoutButtons> = ({
  betId,
  partExtended,
  partValue,
  readySubmitPart,
  readySubmitMax,
  setPartValue,
  setReadySubmitPart,
  setReadySubmitMax,
  countdown,
  submit,
  maxValue,
  newCashoutOffer,
}) => {
  const { t } = useTranslation();
  const { requestedCashout, disabledPartCashout } = useCashoutItemModel(betId);
  const state = requestedCashout && requestedCashout.state;
  const isQueued = state === STATE_QUEUED;
  const isPartCashout = readySubmitPart && !isQueued;
  const isReadySubMax = !readySubmitPart && readySubmitMax && isQueued;

  const onReadySubmitPart = (): void => {
    setReadySubmitMax(false);
    setReadySubmitPart(true);
  };

  const onReadySubmitMax = (): void => {
    setReadySubmitMax(true);
    setReadySubmitPart(false);
  };

  return (
    <S.PartCashoutContainer extended={partExtended}>
      <form onSubmit={(e): void => e.preventDefault()}>
        <S.AmountWrapper
          aria-disabled={!readySubmitPart && readySubmitMax && isQueued}
          isPartCashout={isPartCashout}
          isReadySubMax={isReadySubMax}
        >
          {isPartCashout && countdown !== 0 && (
            <>
              <StyledButton
                disabled={disabledPartCashout}
                onClick={(): void => submit(false, partValue)}
                className="part-cashout-btn"
                type="submit"
                label={formatCashoutOffer(
                  replace(partValue, ',', '.'),
                  disabledPartCashout,
                  true,
                )}
                size={ButtonSize.Big}
              />
              <StyledButton
                onClick={(): void => setReadySubmitPart(false)}
                aria-label={t('common.buttons.close')}
                label=""
                icon={{
                  name: 'close',
                  width: 10,
                  height: 10,
                  className: 'icon-close',
                  isSingle: true,
                }}
                buttonType={ButtonType.LightGhost}
                className="part-cashout-closeBtn"
                size={ButtonSize.Big}
              />
            </>
          )}
          {!readySubmitPart && !isQueued && !disabledPartCashout && (
            <Inputs
              focus={!readySubmitMax}
              value={partValue}
              setValue={setPartValue}
              disabledBtn={readySubmitMax}
              onReadySubmit={onReadySubmitPart}
              inputType="partial"
              maxValue={maxValue}
              focused={partExtended}
            />
          )}

          {isReadySubMax && (
            <Inputs
              value={partValue}
              disabledBtn
              isLock
              onReadySubmit={onReadySubmitMax}
              inputType="max"
            />
          )}
        </S.AmountWrapper>
      </form>
      {!isDesktopView() && !readySubmitPart && !disabledPartCashout && (
        <S.WrapKeyboard>
          <VirtualKeyboard
            value={partValue}
            maxValue={maxValue}
            shouldReplace={false}
            onChange={(val): void => {
              if (setPartValue) {
                const newVal =
                  val === String(newCashoutOffer)
                    ? numeral(+val).format('0.[00]')
                    : val;
                setPartValue(newVal);
              }
            }}
          />
        </S.WrapKeyboard>
      )}
    </S.PartCashoutContainer>
  );
};

export default PartCashoutButtons;
