import styled from 'styled-components';

import CustomLink from '@features/core/routing/CustomLink';
import { TextLarge } from '@features/core/styles/text.styled';
import { MEDIA_1025_MIN } from '@features/core/theming/mediaRequestsList';

export const Root = styled.div`
  display: grid;
  grid-auto-flow: row;
`;

const getLastChildLinkStyles = ({ theme }) => `
margin: ${theme.space_25} 0 0;
`;

export const StyledCustomLink = styled(CustomLink)`
  padding: ${props => props.theme.space_10} 0;
  margin-bottom: ${({ theme }) => theme.space_5};
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  text-decoration: none;
  text-transform: uppercase;

  &.active-item p,
  &.active-item div {
    font-weight: ${({ theme }) => theme.fontBold};
  }

  &:last-child {
    ${({ theme }) => theme.isDesktop && getLastChildLinkStyles};
  }

  @media (${MEDIA_1025_MIN}) {
    &.active-item p,
    &.active-item div {
      color: ${({ theme }) => theme.textLink};
    }
  }
`;

export const ItemLabel = styled(TextLarge)`
  margin-right: ${props => props.theme.space_10};
  color: ${props => props.theme.textMain};
  cursor: pointer;

  &.user-section-link.logout {
    color: ${props => props.theme.textMain};
  }
`;
