import styled from 'styled-components';

import { MEDIA_485_MAX } from '@features/core/theming/mediaRequestsList';

export const RedCard = styled.div`
  height: 13px;
  width: 9px;
  font-size: ${({ theme }) => theme.fontXS};
  display: inline-block;
  position: relative;
  background-color: ${({ theme }) => theme.statusError};
  color: ${props => props.theme.textMain};
  text-align: center;
`;

export const LiveCards = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: absolute;
  left: 2px;
  width: 6px;
  height: 10px;
  top: 3px;
  border-radius: 2px;
  font-size: ${({ theme }) => theme.extraFontsXXS};

  @media screen and (${MEDIA_485_MAX}) {
    align-content: center;
  }

  &.red {
    background-color: ${({ theme }) => theme.statusError};
    color: ${props => props.theme.textMain};
  }

  &.yellow {
    background-color: ${({ theme }) => theme.accent};
    color: ${props => props.theme.main};
  }
`;

export const RedCardWrapper = styled.div`
  margin-right: ${({ theme }) => theme.space_5};
`;

export const StyledRedCard = styled(RedCard)`
  margin-right: ${({ theme }) => theme.space_5};
`;
