import styled from 'styled-components';

import {
  MEDIA_485_MAX,
  MEDIA_486_MIN,
} from '@features/core/theming/mediaRequestsList';

import { hexToRgba } from '@common/helpers/deviceUtil';

import FavoritesButtonType from './FavoritesButton.types';

const getPageButtonStyles = (favoritesType: string, theme) => {
  switch (favoritesType) {
    case FavoritesButtonType.sport:
      return `
                height: 100%;
                justify-content: center;
                justify-items: center;
            `;
    case FavoritesButtonType.live:
      return `
                display: flex;
                justify-content: center;
                width: 40px;
                background-color: ${hexToRgba(theme.textMain, 0.1)};
                flex:1;
            `;
    case FavoritesButtonType.home:
      return `
                height: 15px;
            `;
    default:
      return '';
  }
};

// eslint-disable-next-line import/prefer-default-export
export const FavoritesButton = styled.div<{ favoritesType: string }>`
  display: grid;
  align-items: center;
  cursor: pointer;

  @media screen and (${MEDIA_485_MAX}) {
    width: 28px;
  }

  @media screen and (${MEDIA_486_MIN}) {
    width: 36px;
  }

  ${({ favoritesType, theme }) => getPageButtonStyles(favoritesType, theme)}
`;
