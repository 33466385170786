import styled from 'styled-components';

import { TextNormal } from '@features/core/styles/text.styled';
import CustomLink from '@features/core/routing/CustomLink';
import {
  MEDIA_485_MAX,
  MEDIA_768_MIN,
} from '@features/core/theming/mediaRequestsList';

export const Event = styled.div`
  border-left: ${({ theme }) => theme.borderSecondary};
  margin-top: ${({ theme }) => theme.space_5};
  background: ${props => props.theme.elementNuanceColor};
  padding: ${({ theme }) => theme.space_10} ${({ theme }) => theme.space_15} 0
    ${({ theme }) => theme.space_10};

  &:first-child {
    margin-top: 0;
  }

  &:nth-child(even) {
    background: ${props => props.theme.textActive};
  }
`;

export const EventLabel = styled.div<{ isSuspended: boolean }>`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  min-height: 44px;
  justify-items: start;
  grid-template-columns: 1fr 25%;
  width: 100%;

  & .disabled-custom-link {
    width: 100%;
    padding: unset;
    margin: unset;

    .score {
      padding: 0;
    }
  }

  ${({ isSuspended }) => isSuspended && 'opacity: 0.5;'};
`;
export const Label = styled(TextNormal)`
  color: ${props => props.theme.textMain};
  font-weight: ${({ theme }) => theme.fontBold};
`;
export const Labels = styled.div``;
export const Team = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
`;
export const Scoreboard = styled.div`
  display: grid;
  grid-auto-flow: column;
  margin: 0 0 0 auto;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  & .score {
    padding: 0;
    min-width: unset;
    justify-content: end;

    & div {
      padding-right: 0;
    }
  }

  & .scoreboard > div > div {
    padding-right: 0;
  }

  & .scoreboard > div:last-child > div {
    padding-left: ${({ theme }) => theme.space_5};
  }

  & .score > div > div:last-child > div {
    padding-left: ${({ theme }) => theme.space_5};
  }

  & .scoreboard-wrapper {
    justify-content: end;

    .scoreboard {
      justify-content: end;
    }
  }

  & .period {
    left: 0;
    padding: 0;
  }

  @media screen and (${MEDIA_485_MAX}) {
    & .not-started-timer {
      min-width: unset;
    }
  }
`;
export const BankButtonContainer = styled.div`
  margin-right: ${({ theme }) => theme.space_5};
`;

export const activeBankButtonStyles = theme => `
  background: ${theme.accent};
  color: ${theme.textActive};
`;

export const BankButton = styled.button<{ isActiveBank: boolean }>`
  width: 18px;
  height: 33px;
  background: ${props => props.theme.primaryBackground};
  color: ${props => props.theme.textMain};
  border: ${({ theme }) => theme.border};
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: center;
  margin-right: ${({ theme }) => theme.space_5};
  padding: 0;

  ${({ isActiveBank, theme }) => isActiveBank && activeBankButtonStyles(theme)};
`;
export const BankButtonLabel = styled.span``;

export const Selections = styled.div`
  position: relative;
`;

export const SuspendWrapper = styled.div`
  position: absolute;
  top: calc(50% - 15px);
  right: ${({ theme }) => theme.space_40};

  @media (${MEDIA_768_MIN}) {
    right: ${({ theme }) => theme.space_30};
  }
`;

export const StyledCustomLink = styled(CustomLink)`
  display: flex;
  position: relative;
  width: 100%;
`;
