import styled from 'styled-components';

import {
  TextLarge,
  TextNormal,
  TextSM,
} from '@features/core/styles/text.styled';
import {
  MEDIA_1399_MAX,
  MEDIA_1279_MAX,
} from '@features/core/theming/mediaRequestsList';

import { hexToRgba } from '@common/helpers/deviceUtil';

const getGridBoxExtra = ({ theme }) => {
  if (theme.isDesktop) {
    return `
    min-height: 99.5%;
    background-color: ${theme.inputDefaultText};
    align-content: space-between;
  `;
  }

  return `
    overflow-y: scroll;
    justify-content: space-between;
  `;
};

const desktopSelectStyles = ({ theme }) => {
  return `
  color: ${theme.inputDefaultText};
  font-size: ${theme.fontMD};
  `;
};

const GridBox = styled.div`
  display: grid;
  grid-auto-flow: row;
  align-items: center;
  ${getGridBoxExtra};

  margin-top: ${({ theme }) => theme.space_5};

  &.desktop {
    background-color: ${({ theme }) => theme.textActive};
  }

  &.single {
    margin-top: 0;
  }

  &.single-first,
  &.single {
    width: -webkit-fill-available;
    justify-content: normal;
  }

  input,
  select {
    ${({ theme }) => theme.isDesktop && desktopSelectStyles};
  }
`;

const getPanelExtra = theme => {
  if (theme.isDesktop) {
    return `
     padding: ${theme.space_15} 70px;
  `;
  }

  return `
   padding: ${theme.space_10} ${theme.space_20};
   margin-bottom: ${theme.space_10};
  `;
};

const getPanelDeskMediaStyles = ({ theme }) => `

  @media screen and (${MEDIA_1399_MAX}) {
    padding: ${theme.space_15} 75px;
  }

  @media screen and (${MEDIA_1279_MAX}) {
    padding: ${theme.space_15} ${theme.space_30};
  }
`;

const Panel = styled.div`
  background-color: ${({ theme }) => theme.textActive};
  ${({ theme }) => getPanelExtra(theme)};

  &#privacy_policy {
    padding-bottom: ${({ theme }) => theme.space_20};
    margin-bottom: 0;
  }

  &#terms_of_service {
    padding-top: 0;
    margin-bottom: 0;
  }

  &.password {
    margin-top: -${({ theme }) => theme.space_10};
  }

  &.limits {
    padding: ${({ theme }) => !theme.isDesktop && '0'};
  }

  & .country_code-title {
    margin-top: ${props => props.theme.space_5};
    margin-bottom: ${({ theme }) => theme.space_40};
  }

  &.email .email_title {
    margin-bottom: ${props => props.theme.space_10};
  }

  &.online-account-inner {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.space_30};

    & > div {
      margin-bottom: 0;
    }
  }

  ${({ theme }) => theme.isDesktop && getPanelDeskMediaStyles}
`;

const getTopTitleHeaderDeskStyles = ({ theme }) => `
     padding: ${theme.space_15} 75px;
`;

const TopTitleHeader = styled.p`
  font-size: ${({ theme }) => theme.fontH2};
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontBold};
  text-align: center;
  color: ${({ theme }) => theme.textMain};
  background-color: ${({ theme }) => theme.textActive};
  ${({ theme }) => getPanelExtra(theme)};
  margin: 0;

  &.online-account {
    ${({ theme }) => theme.isDesktop && getTopTitleHeaderDeskStyles};
    text-align: start;
  }
`;

const TopTextHeader = styled(TextLarge)`
  margin-bottom: ${props => props.theme.space_15};
  font-weight: ${({ theme }) => theme.fontBold};
  color: ${({ theme }) => theme.textMain};
`;

const TextSubHeader = styled(TopTextHeader)`
  font-size: ${({ theme }) => theme.fontLG};
  margin-bottom: ${props => props.theme.space_10};
`;

const TextSCHUFA = styled(TopTextHeader)`
  font-size: ${({ theme }) => (theme.isDesktop ? theme.fontMD : theme.fontSM)};
  margin-bottom: ${props => props.theme.space_5};
  margin-top: ${props => props.theme.space_15};
`;

const TopText = styled(TextNormal)`
  color: ${({ theme }) => theme.textMain};
`;

const TextSCHUFAInfo = styled(TextSM)`
  color: ${({ theme }) => theme.textMain};
`;

const FieldsRow = styled.div<{ noMB?: boolean }>`
  position: relative;
  margin-bottom: ${({ theme }) => theme.space_30};

  ${({ noMB }) => noMB && 'margin-bottom: 0px;'}

  &.login {
    margin-bottom: ${props => props.theme.space_10};
  }

  & .with-errors + span,
  & .with-errors .floatingLabel,
  & .with-errors select {
    color: ${({ theme }) => theme.statusError};
    opacity: 1;
  }

  & .with-errors input::placeholder {
    color: ${({ theme }) => hexToRgba(theme.selectTextColor, 0.65)};
  }
`;

const TwoColumnFieldsRow = styled(FieldsRow)<{
  col1: number;
  col2: number;
  col3: number;
}>`
  display: grid;
  grid-auto-flow: column;

  & > div:nth-child(1) {
    grid-auto-columns: ${({ col1 }) => col1}fr;
  }

  & > div:nth-child(2) {
    grid-auto-columns: ${({ col2 }) => col2}fr;
  }

  & > div:nth-child(3) {
    grid-auto-columns: ${({ col3 }) => col3}fr;
  }
`;

const InputDescription = styled(TextSM)`
  font-size: ${({ theme }) => !theme.isDesktop && theme.fontMD};
  color: ${({ theme }) => theme.textMain};
  font-stretch: condensed;
  margin-top: ${props => props.theme.space_5};
`;

const getSubHeaderMobStyles = ({ theme }) => {
  return theme.isAndroid
    ? `margin: ${theme.space_30} ${theme.space_20} 145px;`
    : `margin: ${theme.space_30} ${theme.space_20} 45px;`;
};
const SubmitRow = styled.div`
  ${({ theme }) => !theme.isDesktop && getSubHeaderMobStyles};
`;

const InputTopDescription = styled(TextNormal)`
  color: ${({ theme }) => theme.textMain};
  margin-bottom: ${props => props.theme.space_10};
`;

export default {
  GridBox,
  Panel,
  TopTextHeader,
  TopTitleHeader,
  TopText,
  FieldsRow,
  TwoColumnFieldsRow,
  InputDescription,
  SubmitRow,
  TextSubHeader,
  TextSCHUFA,
  TextSCHUFAInfo,
  InputTopDescription,
};
