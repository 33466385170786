import styled from 'styled-components';

import { TextLarge, TextNormal } from '@features/core/styles/text.styled';
import { getHeaderHeight } from '@features/app/layout/header';
import { footerHeight } from '@features/app/layout/footer';
import { MEDIA_1920_MIN } from '@features/core/theming/mediaRequestsList';

import { hexToRgba } from '@common/helpers/deviceUtil';

const CONTENT_HOLDER_BIG_SCREEN_WIDTH = 1920;

const getTopDesktopPosition = (): string =>
  `position: fixed !important; top: calc(${getHeaderHeight()}px - 10px);`;

const getTopMobilePosition = (): string => `
    position: absolute;
    top: -${getHeaderHeight()}px;
    right: 0;
    bottom: -${footerHeight}px;;
    left: 0;
    height: auto;
    width: auto;
    overflow-y: scroll;
  `;

const getDesktopStyles = (
  isSingleWallet: boolean,
  isSwitcherPopup: boolean,
  theme,
) => `
  ${!isSwitcherPopup ? getTopDesktopPosition() : ''}
  ${
    isSingleWallet && !isSwitcherPopup
      ? 'width: 260px;'
      : 'width: 370px; height: max-content;'
  }
  ${isSwitcherPopup ? 'width: 660px;' : ''}
  padding: ${(isSwitcherPopup || !isSingleWallet) && theme.space_20};
  align-self: center;
  z-index: 5;

`;

const getMobileStyles = (isSwitcherPopup, theme) => `
  width: 100%;
  height: max-content;
  padding: ${isSwitcherPopup ? theme.space_30 : theme.space_20} ${
  isSwitcherPopup ? theme.space_20 : theme.space_10
} ${isSwitcherPopup && !theme.isDesktop ? theme.space_50 : theme.space_20};
  ${isSwitcherPopup && getTopMobilePosition()};
  & > div, > p {
    max-width: 400px;
  }
`;

export const SidebarWrapper = styled.div<{
  isSwitcherPopup: boolean;
  isSingleWallet: boolean;
  rightContentWidth: number;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  right: ${({ isSwitcherPopup, theme, rightContentWidth }) =>
    !isSwitcherPopup && theme.isDesktop ? rightContentWidth : '0'}px;
  background-color: ${({ theme, isSwitcherPopup }) =>
    !isSwitcherPopup ? theme.textActive : theme.main};

  ${({ theme, isSwitcherPopup, isSingleWallet }) =>
    theme.isDesktop &&
    getDesktopStyles(isSingleWallet, isSwitcherPopup, theme)};
  ${({ theme, isSwitcherPopup }) =>
    !theme.isDesktop && getMobileStyles(isSwitcherPopup, theme)};
  z-index: 999 !important;
  box-shadow: 0 10px 15px ${({ theme }) => hexToRgba(theme.textTertiary, 0.5)};

  @media screen and (${MEDIA_1920_MIN}) {
    right: ${({ isSwitcherPopup, rightContentWidth }) =>
      !isSwitcherPopup &&
      (window.document.body.clientWidth +
        window.devicePixelRatio -
        CONTENT_HOLDER_BIG_SCREEN_WIDTH) /
        2 +
        rightContentWidth}px;
  }
`;

export const ContentWrapper = styled.div<{
  isSwitcherPopup: boolean;
  isSingleWallet: boolean;
}>`
  width: 100%;
`;

export const SectionHeader = styled(TextLarge)<{ isSwitcherPopup: boolean }>`
  padding-right: ${({ theme }) => theme.space_30};
  color: ${({ theme }) => theme.textMain};
  text-align: left;
  font-size: ${props => props.theme.fontH3};
  font-weight: ${({ theme }) => theme.fontBold};
`;

export const SectionInfo = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.space_20};
  padding: ${({ theme }) => theme.space_10};
  border: 1px solid ${({ theme }) => theme.accent};
  border-radius: 5px;
`;

export const SectionInfoIcon = styled.div`
  margin-right: ${({ theme }) => theme.space_10};
  transform: translateY(-2px);
`;

export const SectionInfoText = styled.div`
  color: ${({ theme }) => theme.textMain};
  font-size: ${props => props.theme.fontMD};
`;

export const BoldText = styled(TextNormal)`
  display: inline-block;
  font-weight: ${({ theme }) => theme.fontBold};
`;

export const HeaderWrapper = styled.div`
  ${({ theme }) => !theme.isDesktop && 'width: 100%'};
  display: flex;
  align-items: flex-start;
  margin-top: ${({ theme }) => theme.space_30};

  & label {
    transform: translateY(-2px);
  }
`;

export const CloseIconWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  color: ${({ theme }) => theme.textMain};
  align-self: flex-end;
  max-width: 400px;
  height: 20px;

  & svg {
    margin-bottom: ${({ theme }) => theme.space_10};
    cursor: pointer;
  }
`;

export const SectionTitle = styled.span<{ isSwitcherPopup: boolean }>`
  color: ${({ theme }) => theme.textMain};
  text-align: left;
  font-size: ${props => props.theme.fontMD};
`;

export const WalletsList = styled.div<{
  isSwitcherPopup: boolean;
}>`
  display: grid;
  grid-template-columns: ${({ isSwitcherPopup, theme }) =>
    isSwitcherPopup && !theme.isDesktop ? '1fr' : '1fr 1fr'};
  gap: ${({ theme, isSwitcherPopup }) =>
    isSwitcherPopup ? theme.space_20 : theme.space_15};
  margin-top: ${({ theme, isSwitcherPopup }) =>
    isSwitcherPopup ? theme.space_30 : theme.space_10};
  ${({ isSwitcherPopup, theme }) =>
    !isSwitcherPopup && theme.isDesktop && 'justify-items: center'};
`;

export const OtherWalletsHeader = styled(TextLarge)`
  font-weight: ${({ theme }) => theme.fontBold};
  color: ${({ theme }) => theme.textMain};
  margin: ${({ theme }) => theme.space_20} 0 0;
`;
