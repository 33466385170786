import styled from 'styled-components';

import {
  MEDIA_485_MAX,
  MEDIA_486_MIN,
} from '@features/core/theming/mediaRequestsList';

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  height: 100%;

  @media screen and (${MEDIA_485_MAX}) {
    & .darts-scoreboard > div > div {
      min-width: 16px;
    }

    .darts-score {
      padding: 0;
      min-width: 22px !important;
    }
  }

  @media screen and (${MEDIA_486_MIN}) {
    .darts-score {
      padding: 0;
      min-width: 13px;

      &:last-child div {
        padding: ${({ theme }) => theme.space_1};
      }
    }
  }
`;
