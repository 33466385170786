import React from 'react';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';

import { ISwitcherItem } from '@features/wallets/switcher/Switcher.types';
import { Switcher } from '@features/wallets/switcher';

import {
  setBettingSlipError,
  toggleBSWalletType,
  useBettingSlip,
} from '@common/providers/bettingslip/useBettingSlip';
import { IUserWalletType } from '@common/interfaces/user/IUser';
import { IBSWalletType } from '@common/interfaces/bettingslip/IBettingslip';
import {
  formatBalance,
  getActiveWallet,
  getActiveWalletBalance,
  getShopEMoneyBalance,
  getShopEMoneyWallets,
  getWalletName,
} from '@common/helpers/paymentsHelper/walletsHelper';
import { isDesktopView } from '@common/helpers/deviceUtil';

import { Select } from '@ui/components/select';

import * as S from './BettingSlipWalletSwitcher.styled';

const BettingSlipWalletSwitcher = (): JSX.Element => {
  const { t } = useTranslation();
  const affiliateID = useBettingSlip(s => s.affiliateID);
  const walletType = useBettingSlip(s => s.walletType) as string;
  const realMoney = getActiveWalletBalance();
  const bonusMoney = getActiveWalletBalance(IUserWalletType.bonus);
  const eMoneyWallets = getShopEMoneyWallets();
  const eMoneyBalance = getShopEMoneyBalance();
  const regularWallet = getActiveWallet(IUserWalletType.www);
  const bonusWallet = getActiveWallet(IUserWalletType.bonus);

  const toggleBonusWalletHandler = (payload: {
    affiliateID: string;
    walletType: IBSWalletType;
  }): void => {
    setBettingSlipError({});
    toggleBSWalletType(payload);
  };

  const getWalletDescription = (type: IBSWalletType, balance): string => {
    switch (type) {
      case IBSWalletType.REGULAR:
        return `${t('bonusWallet.bs_section.bs_real_money')} ${formatBalance(
          balance,
        )}`;
      case IBSWalletType.BONUS:
        return `${t('bonusWallet.bs_section.bs_bonus_money')} ${formatBalance(
          balance,
        )}`;
      case IBSWalletType.SHOP:
      default:
        return `${t('bonusWallet.bs_section.bs_shop_money')} ${formatBalance(
          balance,
        )}`;
    }
  };

  const getWalletsList = (): ISwitcherItem[] => {
    const walletsList = [] as ISwitcherItem[];

    walletsList.push({
      walletType: IBSWalletType.REGULAR,
      title: getWalletDescription(IBSWalletType.REGULAR, realMoney),
      affiliateID: regularWallet?.affiliate_id || '',
    });

    if (bonusMoney) {
      walletsList.push({
        walletType: IBSWalletType.BONUS,
        title: getWalletDescription(IBSWalletType.BONUS, bonusMoney),
        affiliateID: bonusWallet?.affiliate_id || '',
        icon: {
          width: '12',
          height: '15',
          name: 'gift',
        },
      });
    }

    if (eMoneyBalance) {
      const eMoneyWalletsList = map(eMoneyWallets, wallet => ({
        walletType: IBSWalletType.SHOP,
        title: getWalletDescription(
          IBSWalletType.SHOP,
          parseFloat(wallet.balance),
        ),
        affiliateID: wallet.affiliate_id,
        description: getWalletName(wallet),
        icon: {
          width: '16',
          height: '16',
          name: 'credit-card',
        },
      }));
      walletsList.push(...eMoneyWalletsList);
    }
    return walletsList;
  };

  if (getWalletsList().length > 3 && isDesktopView()) {
    return (
      <S.SelectWrap>
        <Select
          items={getWalletsList()}
          activeItem={affiliateID}
          activeType={walletType}
          onSelectItem={toggleBonusWalletHandler}
        />
      </S.SelectWrap>
    );
  }

  return (
    <Switcher
      items={getWalletsList()}
      activeItem={affiliateID}
      activeType={walletType}
      onSelectItem={toggleBonusWalletHandler}
    />
  );
};

export default BettingSlipWalletSwitcher;
