import styled from 'styled-components';

import CustomLink from '@features/core/routing/CustomLink';
import { TextNormal } from '@features/core/styles/text.styled';

export const LimitsDialogLink = styled(CustomLink)`
  display: grid;
  grid-auto-flow: column;
  text-decoration: none;
  align-items: center;
  justify-content: space-between;
  color: ${props => props.theme.selectTextColor};
  cursor: pointer;
  margin-bottom: ${({ theme }) => theme.space_10};

  .mi-info-circle-solid {
    padding-right: ${({ theme }) => theme.space_10};
  }
`;

export const LimitTitle = styled(TextNormal)`
  font-weight: ${({ theme }) => theme.fontBold};
  font-stretch: condensed;
  font-style: normal;
  color: ${props => props.theme.highlightWrapperBackground};
  font-size: ${({ theme }): string => theme.fontLG};
`;

export const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
`;

export const LimitSubTitle = styled(TextNormal)`
  font-weight: ${({ theme }) => theme.fontRegular};
  color: ${props => props.theme.highlightWrapperBackground};
  margin-bottom: ${({ theme }) => theme.space_20};

  a {
    color: ${props => props.theme.textLink};
  }
`;

export const LimitsContainer = styled.div`
  background-color: ${props => props.theme.textActive};
  margin-bottom: ${({ theme }) => (theme.isDesktop ? theme.space_20 : '0')};

  & > div:nth-child(odd) {
    background-color: ${({ theme }) =>
      theme.isDesktop ? theme.bodyBackground : theme.tertiaryBackground};
  }

  .form-input-error .sufix {
    font-weight: ${({ theme }) => theme.fontBold};
    color: ${({ theme }) => theme.inputDefaultText};
  }
`;

export const InputInfoWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: start;
  grid-template-columns: 25px 1fr;
  color: ${({ theme }) => theme.textMain};
  margin: ${({ theme }) => theme.space_15} 0;
`;

export const LimitRow = styled.div`
  display: grid;
  grid-auto-flow: row;
  align-items: ${({ theme }) => (theme.isDesktop ? 'center' : 'baseline')};
  justify-content: space-between;
  padding: ${props => props.theme.space_10};
  color: ${props => props.theme.highlightWrapperBackground};
  position: relative;
  grid-auto-columns: 1fr;
  margin-left: ${({ theme }) => theme.isDesktop && theme.space_30};

  input {
    padding: ${({ theme }) => theme.space_20};
    text-align: end;
  }

  .sufix {
    font-size: ${({ theme }) => theme.fontMD};
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .form-input-error input {
    height: 40px;
    font-weight: ${({ theme }) => theme.fontBold};
    font-size: ${props => props.theme.fontMD};
  }

  .error-message {
    right: 0;
    ${({ theme }) => theme.isDesktop && 'width: fit-content;'}
  }

  input + div {
    color: ${props => props.theme.textActive};
  }

  .with-errors input + div {
    color: ${props => props.theme.statusError};
  }
`;

export const NotificationWrapper = styled.div<{ isError: boolean }>`
  & div {
    align-items: start;
    font-size: ${({ theme }) => theme.fontMD};
    color: ${({ isError, theme }) => isError && theme.statusError};
  }

  & svg {
    color: ${({ isError, theme }) =>
      isError ? theme.statusError : theme.main};
  }
`;

const getPaddingLimitWrapperMobileStyles = theme => `
padding: ${theme.space_10} ${theme.space_20};
`;
export const FormRegisterLimitWrapper = styled.div`
  position: relative;
  ${({ theme }) =>
    !theme.isDesktop && getPaddingLimitWrapperMobileStyles(theme)};

  .limit-tooltip {
    width: 100%;
    right: 0;
    top: 15%;
  }
`;

export const ActiveArrow = styled.div`
  position: absolute;
  top: 12%;
  left: calc(100% - 35px);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px;
  border-color: transparent transparent ${({ theme }) => theme.textMain}
    transparent;
`;
