/* eslint-disable import/prefer-default-export */
export enum IErrorPayments {
  PayerAccountDoesNotExist = '20', // missing translations
  MissingDepositDetails = '21', // missing translations
  PayerDataMismatch = '22', // missing translations
  GatewayError = '23', // missing translations
  RejectedByGateway = '28', // missing translations
  MissingParameter = '46', // missing translations
  InvalidIBAN = '47',
  InsufficientFunds = '50',
  AmountNotAllowed = '52', // used response message instead of translation
  ServiceNotAvailable = '53',
  DepositRequired = '54',
  PaymentOptionAlreadyInUse = '55',
  RemoteCountryMismatch = '56',
  MissingAmount = '57', // missing translations
  PaymentMethodNotAllowed = '58', // missing translations
  InvalidField = '59',
  PaypalCasino = '60',
  Rejected = '61',
  TransactionRefused = '62',
  ClosedLoopError = '68',
  SkrillInvalidMail = '70',
  DailySelfLimitExceeded = '71',
  WeeklySelfLimitExceeded = '72',
  MonthlySelfLimitExceeded = '73',
  MonthlyGlobalLimitExceeded = '74',
  MonthlyGlobalLimitUnknown = '75', // missing translations
  UnknownService = '79',
  AccountHolderMismatch = '80',
  Unauthorized = '92', // missing translations
  SystemError = '99',
}
