import styled from 'styled-components';

import CustomLink from '@features/core/routing/CustomLink';
import {
  MEDIA_1366_MIN,
  MEDIA_1600_MAX,
  MEDIA_1400_MIN,
  MEDIA_1399_MAX,
  MEDIA_1280_MIN,
  MEDIA_1600_MIN,
  MEDIA_1365_MAX,
} from '@features/core/theming/mediaRequestsList';

export const Root = styled(CustomLink)`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: ${props => (!props.theme.isDesktop ? '40px' : '32px')};
  font-size: ${props =>
    !props.theme.isDesktop ? props.theme.fontLG : props.theme.fontMD};
  padding-left: ${props => props.theme.space_10};
  max-width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  border-bottom: ${({ theme }) => theme.border};

  .chevron {
    padding: 0 ${({ theme }) => theme.space_10};
  }
`;

export const LeftSide = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  color: ${props => props.theme.selectTextColor};

  @media screen and (${MEDIA_1600_MIN}) {
    grid-auto-columns: auto;
    justify-content: space-between;
  }
`;

export const LeftSideWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-items: center;
  align-content: center;
  align-items: center;
  justify-content: start;
`;

const favoriteBorder = ({ theme }) =>
  `border-left: 1px solid ${theme.highlightsHeaderBackground};`;

export const RightSide = styled.div<{
  hasFavorite?: boolean;
  withoutPadding?: boolean;
}>`
  height: 100%;
  min-width: ${({ hasFavorite }) => (hasFavorite ? '30' : '15')}px;
  padding: 0 ${props => !props.withoutPadding && props.theme.space_10};
  text-align: center;
  color: ${props => props.theme.textMain};
  position: relative;
  ${({ hasFavorite }) => !hasFavorite && favoriteBorder};
`;

export const ItemLabelPrefix = styled.span`
  display: none;

  @media screen and (${MEDIA_1280_MIN}) and (${MEDIA_1365_MAX}) {
    display: inline;
  }
`;

export const ItemSubLabel = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-left: ${({ theme }) => theme.space_5};
  display: inline-block;
`;

export const ItemLabel = styled.div<{ isSelected: boolean }>`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  height: ${props => (!props.theme.isDesktop ? '40px' : '32px')};
  width: 100%;
  align-items: center;
  color: ${({ theme, isSelected }) =>
    isSelected ? theme.accent : theme.textMain};

  & b.title {
    color: ${({ theme, isSelected }) =>
      isSelected ? theme.accent : theme.textMain};
    font-size: ${({ theme }) => theme.fontMD};

    @media screen and (${MEDIA_1280_MIN}) and (${MEDIA_1365_MAX}) {
      display: none;
    }
  }
`;

export const getStylesByCheckboxStatus = (status, color) => {
  if (status === 'checked') {
    return `
      background-color: ${color};
      border-color: ${color};
    `;
  }

  if (status === 'unChecked') {
    return 'background-color: transparent';
  }

  return `
    background-color: ${color};
    border-color: ${color};
  `;
};

export const CustomCheckbox = styled.div<{ checkboxStatus: string }>`
  box-sizing: border-box;
  display: grid;
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 12px;
  height: 12px;
  margin-right: ${props => props.theme.space_5};
  border: 1px solid ${props => props.theme.textMain};
  background-color: transparent;
  border-radius: 3px;
  ${({ checkboxStatus, theme }) =>
    getStylesByCheckboxStatus(checkboxStatus, theme.accent)}
`;

export const CheckboxWrap = styled.div`
  display: inline-block;
`;

export const AllGames = styled.div`
  margin-left: auto;
  margin-right: ${({ theme }) => theme.space_15};
  text-decoration: underline;
  color: ${props => props.theme.accent};
  white-space: nowrap;
  font-size: ${({ theme }) => !theme.isDesktop && theme.fontMD};
`;

export const Chevron = styled.span`
  display: grid;
  justify-items: end;
  align-items: center;
  justify-content: end;
`;

export const ChevronWrap = styled.div`
  display: grid;
  justify-items: end;
  align-items: center;
  justify-content: end;
  height: ${props => (!props.theme.isDesktop ? '40px' : '32px')};
  color: ${props => props.theme.textMain};
  ${({ theme }) => theme.isDesktop && 'margin-left: auto;'}
`;

export const CategoryLabel = styled.div`
  white-space: nowrap;
  display: contents;

  @media (${MEDIA_1280_MIN}) and (${MEDIA_1399_MAX}) {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (${MEDIA_1366_MIN}) and (${MEDIA_1399_MAX}) {
    width: 125px;
  }

  @media (${MEDIA_1400_MIN}) and (${MEDIA_1600_MAX}) {
    max-width: 160px;
  }
`;

export const CountEvens = styled.span``;
