import styled from 'styled-components';

import {
  MEDIA_485_MAX,
  MEDIA_486_MIN,
} from '@features/core/theming/mediaRequestsList';

const NotStartedInner = styled.div`
  max-width: ${({ theme }) => theme.isDesktop && 'fit-content'};

  @media screen and (${MEDIA_486_MIN}) {
    max-width: ${({ theme }) => !theme.isDesktop && '50px'};
  }

  @media screen and (${MEDIA_485_MAX}) {
    & .not-started-timer {
      min-width: 45px;
      grid-template-columns: min-content;
    }
  }
`;

export default NotStartedInner;
