import find from 'lodash/find';
import includes from 'lodash/includes';
import { useMemo } from 'react';

import { PageName } from '@features/core/routing';

import { useEventsListState } from '@common/providers/events/eventList/useEventsList';
import {
  cashoutDefaultButtonValue,
  isLiveOrder,
} from '@common/helpers/cashoutHelper';
import { useBets } from '@common/providers/bets/useBets';
import { useCashoutState } from '@common/providers/cashout/useCashout';
import { IBetDetail } from '@common/interfaces/bets/IBetDetail';
import { ICashoutModel } from '@common/interfaces/cashout/ICashout';
import { isMatchWebRoutes } from '@common/providers/router/helper';

const useCashoutItemModel = (betId: string): ICashoutModel => {
  const requestedCashoutState = useCashoutState(s => s.requested);
  const cashoutFavorites = useCashoutState(s => s.favorites);
  const minCashout = useCashoutState(s => s.min_cashout);
  const loading = useCashoutState(s => s.loading);
  const openBets = useBets(s => s.openBets);
  const updates = useBets(s => s.updates);
  const betDetail = useBets(s => s.bet_detail);
  const events = useEventsListState(state => state.cashout.data.events);
  const isBetDetailPage = isMatchWebRoutes(PageName.USER_BET_ITEM);

  return useMemo(() => {
    const cashoutItem = find(openBets, ['id', betId]) as IBetDetail;
    const updatedBet = updates?.[betId];
    const updatedItem = {
      ...cashoutItem,
      ...(isBetDetailPage ? betDetail : {}),
      ...updatedBet,
    };
    const requestedCashout = requestedCashoutState[betId];
    // do not use here value from bet api = updatedItem?.cashout_offer
    // we need fresh cashout value from sockets = updates?.[betId]?.cashout_offer
    const cashoutOffer =
      requestedCashout?.cashout_detail?.amount ||
      updatedBet?.cashout_offer ||
      cashoutDefaultButtonValue;
    const cashoutDisabled = parseFloat(cashoutOffer) < minCashout;
    const isFavorite = includes(cashoutFavorites, betId);
    const isLive = isLiveOrder(events, updatedItem);
    const disabledPartCashout =
      loading || cashoutDisabled || !Number(cashoutOffer);

    return {
      isFavorite,
      cashoutOffer,
      requestedCashout,
      isLive,
      cashoutDisabled,
      remainingRisk: updatedItem.remaining_risk,
      selections: updatedItem.selections,
      totalStake: updatedItem.total_stake,
      type: updatedItem.type,
      unavailableSelectionId: updatedItem.unavailable_selection_id,
      isBonus: updatedItem.is_bonus,
      isShopEMoney: updatedItem.is_shop_emoney,
      offerToken: updatedItem.offer_token,
      disabledPartCashout,
    };
  }, [betId, updates, minCashout, cashoutFavorites, requestedCashoutState]);
};

export default useCashoutItemModel;
