// mobile
export const MEDIA_485_MAX = 'max-width: 485px';
export const MEDIA_486_MIN = 'min-width: 486px';
export const MEDIA_767_MAX = 'max-width: 767px';

// tablet

export const MEDIA_768_MIN = 'min-width: 768px';
export const MEDIA_1024_MAX = 'max-width: 1024px';
export const MEDIA_1025_MIN = 'min-width: 1025px';
export const MEDIA_1279_MAX = 'max-width: 1279px';

// desktop

export const MEDIA_1100_MAX = 'max-width: 1100px'; // oddsboost hero layout desktop starts
export const MEDIA_1101_MIN = 'min-width: 1101px';
export const MEDIA_1280_MIN = 'min-width: 1280px';
export const MEDIA_1399_MAX = 'max-width: 1399px';
export const MEDIA_1400_MIN = 'min-width: 1400px';
export const MEDIA_1600_MAX = 'max-width: 1599px';
export const MEDIA_1600_MIN = 'min-width: 1600px';
export const MEDIA_1919_MAX = 'min-width: 1919px';
export const MEDIA_1920_MIN = 'min-width: 1920px';

// rare sizes

export const MEDIA_340_MAX = 'max-width: 340px';
export const MEDIA_340_MIN = 'min-width: 340px';
export const MEDIA_420_MIN = 'min-width: 420px';
export const MEDIA_400_MIN = 'min-width: 400px';
export const MEDIA_599_MAX = 'max-width: 599px';
export const MEDIA_600_MIN = 'min-width: 600px';
export const MEDIA_900_MIN = 'min-width: 900px';

export const MEDIA_1365_MAX = 'max-width: 1365px';
export const MEDIA_1366_MIN = 'min-width: 1366px';
export const MEDIA_1750_MAX = 'max-width: 1750px';
export const MEDIA_1800_MIN = 'min-width: 1800px';
export const MEDIA_2100_MIN = 'min-width: 2100px';
export const MEDIA_3000_MIN = 'min-width: 3000px';

export const MEDIA_650_MAX_HEIGHT = 'max-height: 650px';
