import React from 'react';
import reduce from 'lodash/reduce';
import { Route, Switch } from 'react-router-dom';
import includes from 'lodash/includes';

import RouteData from '@features/core/router/IRoute.types';
import routes from '@features/core/router';
import {
  commonRoutes,
  iframeUserAccountRoutes,
  loggedInRedirectToHomeRoutes,
  loggedOutRedirectToHomeRoutes,
  userAccountRoutes,
} from '@features/core/router/routeNames';
import { useServices } from '@features/core/services';
import { getPageURL, PageName } from '@features/core/routing/linkAliases';
import ErrorBoundary from '@features/core/error/ErrorBoundary';
import ConditionalRoute from '@features/core/router/ProtectedRoute';

import * as cookies from '@common/constants/cookie';
import { useUserState } from '@common/providers/user/useUserState';
import { isIframeView } from '@common/helpers/appLayoutHelper';
import { getDefaultRouteName } from '@common/providers/router/helper';
import { UserLoadingState } from '@common/providers/user/actions/actionTypes';

const Routes: React.FC = (): JSX.Element => {
  const { cookie } = useServices();
  const urlParams = new URLSearchParams(window.location.search);
  const token = cookie.get(cookies.TOKEN) || urlParams.get('token');
  const isLoggedIn =
    useUserState(s => s.loadingState) === UserLoadingState.LOGGED_IN;
  const userRoutes = isIframeView()
    ? iframeUserAccountRoutes
    : userAccountRoutes;

  return (
    <ErrorBoundary>
      <Switch>
        {reduce(
          userRoutes,
          (acc: JSX.Element[], e: string) => {
            const route: RouteData = routes[e];
            const condition = !!(token || isLoggedIn);
            return acc.concat(
              <ConditionalRoute
                key={e}
                path={route.path}
                exact={route.exact}
                component={route.component}
                condition={condition}
                redirectPath={getPageURL(PageName.LOGIN)}
              />,
            );
          },
          [],
        )}
        {reduce(
          commonRoutes,
          (acc: JSX.Element[], e: string) => {
            const route: RouteData = routes[e];
            if (route !== undefined) {
              if (includes(loggedInRedirectToHomeRoutes, e) && isLoggedIn) {
                // Redirect logged-in users away from certain routes
                return acc.concat(
                  <ConditionalRoute
                    key={e}
                    path={route.path}
                    component={route.component}
                    exact={route.exact}
                    condition={false} // Force redirect
                    redirectPath={getPageURL(getDefaultRouteName())}
                  />,
                );
              }

              // todo: should clear interaction token and isLoggedIn
              if (
                includes(loggedOutRedirectToHomeRoutes, e) &&
                !isLoggedIn &&
                !token
              ) {
                // Redirect logged-out users away from certain routes
                return acc.concat(
                  <ConditionalRoute
                    key={e}
                    path={route.path}
                    component={route.component}
                    exact={route.exact}
                    condition={false} // Force redirect
                    redirectPath={getPageURL(PageName.LOGIN)}
                  />,
                );
              }

              return acc.concat(
                <Route
                  key={e}
                  path={route.path}
                  component={route.component}
                  exact={route.exact}
                />,
              );
            }
            return acc;
          },
          [],
        )}
      </Switch>
    </ErrorBoundary>
  );
};

export default Routes;
