import styled from 'styled-components';

import { MEDIA_1280_MIN } from '@features/core/theming/mediaRequestsList';

import { hexToRgba } from '@common/helpers/deviceUtil';

export const styles = {
  height: 42,
};

export const pageHeaderHeight = 42;

export const Header = styled.div<{
  isSportHeaderStyle: boolean;
}>`
  display: grid;
  grid-template-columns: 42px 1fr 42px;
  min-height: ${pageHeaderHeight}px;
  height: ${pageHeaderHeight}px;
  background-color: ${({ isSportHeaderStyle, theme }) =>
    isSportHeaderStyle ? theme.textActive : theme.bodyBackground};
  align-items: center;
`;

export const Title = styled.h1`
  font-size: ${props => props.theme.fontLG};
  font-weight: ${({ theme }) => theme.fontBold};
  width: 100%;
  text-align: center;
  display: grid;
  justify-content: center;
  justify-items: center;
  color: ${props => props.theme.textMain};

  a {
    color: ${props => props.theme.textMain};
    text-decoration: none;
  }

  @media (${MEDIA_1280_MIN}) {
    text-transform: uppercase;
    font-size: ${props => props.theme.fontMD};
  }
`;

export const EmptyBox = styled.div`
  width: ${styles.height}px;
  height: 100%;
`;
export const backButtonBackgroundColor = ({ theme }) =>
  `${hexToRgba(theme.primaryBackground, 0.28)}`;

export const BackButton = styled.div`
  width: ${styles.height}px;
  height: 100%;
  justify-content: center;
  display: grid;
  align-items: center;
  cursor: pointer;
  background-color: ${backButtonBackgroundColor};
`;

export const FavoritesButton = styled.div`
  width: calc(100% - 15px);
  height: 100%;
  justify-content: end;
  display: grid;
  align-items: center;
  cursor: pointer;
  justify-items: end;

  & svg {
    padding: ${({ theme }) => theme.space_10};
  }
`;

export const ImageStyled = styled.img`
  filter: brightness(0) invert(1);
`;

export const ImageWrapper = styled.div`
  height: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  justify-items: center;
  cursor: pointer;
`;
