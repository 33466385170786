import styled from 'styled-components';

import { MEDIA_486_MIN } from '@features/core/theming/mediaRequestsList';

// eslint-disable-next-line import/prefer-default-export
export const TimerWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  color: ${({ theme }) => theme.textMain};

  @media screen and (${MEDIA_486_MIN}) {
    padding-right: ${({ theme }) => theme.space_5};
  }
`;
