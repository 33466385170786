import styled from 'styled-components';

import {
  MEDIA_1600_MIN,
  MEDIA_485_MAX,
  MEDIA_1600_MAX,
  MEDIA_1280_MIN,
  MEDIA_1919_MAX,
  MEDIA_1920_MIN,
  MEDIA_486_MIN,
} from '@features/core/theming/mediaRequestsList';

import { PageType } from '@common/interfaces';

export const getPredictionDivDesktop = fontSize => `
  p {
    font-size: ${fontSize};
  }
`;

const getDeskPadding = ({ theme }) => `${theme.space_5} 0;`;

export const PredictionsDiv = styled.div<{ isDetail: boolean }>`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: ${({ isDetail }) => isDetail && '1fr 60%;'};
  height: 100%;
  align-items: center;
  align-content: center;

  & > div,
  div:has(button.disabled) {
    gap: ${({ theme }) => theme.space_1};
  }

  .single {
    display: grid;
    grid-template-columns: 1fr;
    align-content: center;
    grid-gap: ${({ theme }) => theme.space_1};

    ${({ theme }) => theme.isDesktop && getPredictionDivDesktop(theme.fontMD)};
  }

  .dual {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-content: center;
  }

  .triple {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-content: center;
  }

  .dual,
  .triple {
    border-radius: 0;
    margin-top: ${({ theme }) => theme.space_1};

    &:first-child {
      border-top-left-radius: 3px;
    }

    &:last-child {
      border-bottom-right-radius: 3px;
    }
  }

  .live-dual {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-content: center;
  }

  .live-triple {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-content: center;
  }

  .quarter {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: ${({ theme }) => theme.space_5};
    align-content: center;

    @media screen and (${MEDIA_1919_MAX}) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media screen and (${MEDIA_1280_MIN}) and (${MEDIA_1600_MAX}) {
      grid-template-columns: 1fr 1fr;
      width: 400px;
    }

    @media screen and (${MEDIA_1600_MIN}) and (${MEDIA_1919_MAX}) {
      width: 610px;
    }

    @media screen and (${MEDIA_1920_MIN}) {
      width: 820px;
    }

    button {
      padding: ${({ theme }) => theme.space_10} ${({ theme }) => theme.space_5};
      border-radius: 3px;
    }

    span {
      font-size: ${({ theme }) => theme.fontMD};
    }
  }

  .live-dual,
  .live-triple {
    & > button {
      border-radius: 0;

      &:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }

      &:last-child {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }
  }

  .dual.one-row,
  .triple.one-row {
    & > button {
      border-radius: 0;

      &:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }

      &:last-child {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }
  }

  @media screen and (${MEDIA_485_MAX}) {
    & div {
      gap: ${({ theme }) => theme.space_1} ${({ theme }) => theme.space_1};
    }
  }

  @media screen and (${MEDIA_486_MIN}) {
    grid-auto-columns: ${props => !props.isDetail && 'auto 1fr auto;'};
    padding: ${({ isDetail, theme }) =>
      isDetail && theme.isDesktop ? 0 : getDeskPadding};
  }
`;

export const getDetailLabelStyles = ({ theme }) => `
  padding-right: ${theme.space_10};
  display: grid;
  grid-template-columns: 1fr auto;
  align-self: self-start;
  min-height: ${theme.isDesktop ? '34px' : '44px'};
  font-size: ${theme.fontMD};
  align-items: center;
  justify-content: space-between;
  font-weight: ${theme.fontBold};;
  min-width: 40%;
  box-sizing: border-box;
`;

export const Label = styled.div<{ pageType?: string }>`
  align-self: center;
  ${({ pageType }) => pageType === PageType.DETAIL && getDetailLabelStyles}
`;

const getPredictionsWrapperMargin = ({ theme }) => `0 ${theme.space_5};`;

export const PredictionWrapper = styled.div`
  box-sizing: border-box;
  border-radius: 4px;
  overflow: hidden;
  height: fit-content;
  margin: ${({ theme }) => !theme.isDesktop && getPredictionsWrapperMargin};

  &.one-row {
    height: ${({ theme }) => (theme.isDesktop ? '33px' : '44px')};
  }

  & button {
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    align-items: center;
  }

  @media screen and (${MEDIA_486_MIN}) {
    min-width: 60%;
  }
`;
