import styled from 'styled-components';

import { TextLarge } from '@features/core/styles/text.styled';
import { MEDIA_1399_MAX } from '@features/core/theming/mediaRequestsList';

const mobileHeader = ({ theme }) => `
  margin: ${theme.space_10} 0 0;
  padding: ${theme.space_10} 0;
  background-color: ${theme.primaryAccentBackground};
  width: auto;
  
  div {
    font-size: ${theme.fontMD};
    text-transform: capitalize;
  }
`;

const stylesForBets = `
  width: 800px;  
  @media (${MEDIA_1399_MAX}) {
    width: 730px;
  }
`;

const qrCodeStyles = ({ theme }) => `
margin-top: 0;
padding: ${theme.space_10} 0;
justify-content: center;
`;

export const Wrapper = styled.div<{
  isBets?: boolean;
  isChangePassword?: boolean;
  isQRcode?: boolean;
}>`
  display: grid;
  margin-top: ${({ theme }) => theme.space_10};
  padding-bottom: ${({ theme }) => theme.space_15};
  width: ${({ isChangePassword }) => (isChangePassword ? '705' : '446')}px;
  color: ${({ theme }) => theme.textMain};
  text-align: center;

  div {
    font-size: ${props => props.theme.fontH1};
    text-transform: uppercase;
  }

  ${({ isBets }) => isBets && stylesForBets}
  ${({ isQRcode }) => isQRcode && qrCodeStyles}

  ${({ theme }) => !theme.isDesktop && mobileHeader}

  transition: all 0.3s;
`;

export const BackButton = styled.div`
  width: 42px;
  height: 100%;
  justify-content: center;
  justify-items: center;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  cursor: pointer;

  & svg > path {
    fill: ${props => props.theme.textMain};
  }
`;

export const ImageStyled = styled.img`
  filter: brightness(0) invert(1);
`;

export const ImageWrapper = styled.div`
  height: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  justify-items: center;
  grid-auto-columns: 1fr;
`;

export const HighlightBoldHeaderLabel = styled(TextLarge)`
  font-weight: ${({ theme }) => theme.fontBold};
  font-size: ${props => props.theme.fontH1};
  text-transform: uppercase;
`;
