import styled from 'styled-components';

import { MEDIA_767_MAX } from '@features/core/theming/mediaRequestsList';

const SystemsContainer = styled.div<{
  systems: number;
  isSystemTab: boolean;
}>`
  background: ${(props): string => props.theme.main};

  @media screen and (${MEDIA_767_MAX}) {
    ${({ systems, theme, isSystemTab }): string =>
      systems > 5 && theme.isDesktop && isSystemTab
        ? 'overflow: auto; max-height: 200px;'
        : ''}
  }
`;

export default SystemsContainer;
