import styled from 'styled-components';

import {
  MEDIA_1279_MAX,
  MEDIA_485_MAX,
  MEDIA_486_MIN,
  MEDIA_768_MIN,
} from '@features/core/theming/mediaRequestsList';

export const disabledButtonStyles = color => `
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  color: ${color};

  span ~ svg {
    margin: 0;
  };
`;

export const oddsHoverStyles = theme => `
  &:hover, &:hover span {
    color: ${theme.accent};
    font-weight: ${theme.fontBold};
  }
`;

export const oddUpStyles = borderColor => `
  &::before{
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 15px 15px 0;
    border-color: transparent ${borderColor} transparent transparent;
    content: "";
    position: absolute;
  }
`;

export const oddDownStyles = borderColor => ` 
  &::before {
    bottom: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 15px 15px;
    border-color: transparent  transparent ${borderColor} transparent;
    content: "";
    position: absolute;
  }
`;

const getLongLabelPadding = ({ theme }) => `padding: 0 ${theme.space_5}`;

const getStyledButtonFontStyles = (selected, theme) => `
font-weight: ${!selected ? theme.fontRegular : theme.fontBold};
`;

export const StyledButton = styled.button<{
  selected?: boolean;
  isDisabled?: boolean;
  isDetail?: boolean;
  up?: boolean;
  down?: boolean;
}>`
  background-color: ${props => {
    return props.selected
      ? props.theme.accent
      : props.theme.highlightsHeaderBackground;
  }};
  height: ${({ theme }) => (theme.isDesktop ? '35px' : '40px')};
  position: relative;
  outline: none;
  border: ${({ theme }) => theme.border};
  text-align: center;
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: ${props => props.theme.fontMD};
  ${({ selected, theme }) => getStyledButtonFontStyles(selected, theme)};
  color: ${({ selected, theme }) =>
    selected ? theme.textActive : theme.textPrimary};
  ${({ up, theme }) => up && oddUpStyles(theme.statusWon)}
  ${({ down, theme }) => down && oddDownStyles(theme.statusError)};

  @media screen and (${MEDIA_768_MIN}) and (${MEDIA_1279_MAX}) {
    font-size: ${props => props.theme.fontMD};
  }

  @media screen and (${MEDIA_486_MIN}) {
    min-width: ${({ theme }) =>
      theme.isDesktop ? 'calc((100% - 41px) / 3)' : '42px'};
    padding: 0 ${({ theme }) => theme.space_10};
    ${({ theme }) => !theme.isDesktop && 'word-spacing: 4px'};
  }

  @media screen and (${MEDIA_485_MAX}) {
    padding: ${({ isDetail, theme }) =>
      isDetail ? theme.space_10 : theme.space_5};
  }

  &.disabled {
    cursor: auto;
  }

  & .boldBtn {
    text-align: end;
  }

  &.small span {
    font-size: ${({ theme }) => theme.fontMD};
  }

  &.small p {
    font-size: ${({ theme }) => theme.extraFontsXXS};
  }

  svg {
    margin: 0 auto;
  }

  & svg path {
    fill: ${props => props.theme.inputDefaultText};
  }

  ${props =>
    props.isDisabled && disabledButtonStyles(props.theme.inputDefaultText)}

  &.show_more {
    color: ${({ theme }) => theme.textMain};
  }

  &.long-label {
    ${({ theme }) => !theme.isDesktop && getLongLabelPadding};
  }

  ${({ theme, selected, isDisabled }) =>
    theme.isDesktop && !selected && !isDisabled && oddsHoverStyles(theme)}
`;

export const PredictionsLabel = styled.span<{ selected: boolean }>`
  text-align: left;
  font-size: ${({ theme }) => theme.fontMD};
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  color: ${props =>
    props.selected ? props.theme.textActive : props.theme.textPrimary};
`;
